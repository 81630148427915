import React, { Component } from "react";
import { Row, Container, Col } from "reactstrap";

const IBMPlanningAnalyticsImplementation = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Row className="api-services-content">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h4>
                  <b className="color-grey">
                    Planning & Budgeting Implementation
                  </b>
                </h4>
                <br />
                <p>
                  <b className="color-grey">In-Depth Consultation:</b> We start
                  by conducting a comprehensive analysis of your financial
                  environment, understanding your company's workflow, historical
                  data handling, and strategic priorities. This allows us to
                  identify gaps and opportunities where Planning & Budgeting
                  tools can make a significant impact.
                </p>
                <br />
                <p>
                  <b className="color-grey">Strategic Planning:</b> Leveraging
                  insights gained during consultation, we craft a detailed
                  implementation plan that includes milestones, timelines, and
                  clearly defined objectives to ensure that the integration
                  supports your strategic goals.
                </p>
                <br />
                <p>
                  <b className="color-grey">Seamless Integration:</b> Our team
                  meticulously manages the integration process, focusing on
                  minimizing downtime and disruption. We handle all aspects of
                  deployment, from software setup to system testing, ensuring
                  that IBM Planning Analytics melds seamlessly with your
                  existing IT infrastructure.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default IBMPlanningAnalyticsImplementation;
